















































































import { Notify } from "vant"
import HeadTop from "@/components/ShareComponent/HeadTop.vue"
import List from "../../components/ShareComponent/PullDownUp.vue"
import PullItem from "../../components/ShareComponent/PullSelItem.vue"
import AllItem from "../../components/ExpressComponent/AllExpressCom.vue"
import UpDownCom from '../../components/ShareComponent/ListBottom.vue'
import Stroage from "../../util/Storage"
import { GetTimes,GetSchoolRoom } from "../../Api/Basics/index"
import { GetFrom,AllExpress as UpAllExpress } from "../../Api/Express/index"
import { StoreExpress } from "../../store/modules/Express/Express"
import { UserStore } from "../../store/modules/User"
import { FilterPullItem,IsPageNo } from "../../util/filter"
import { GetTimeType,GetFromType,RoomType,AllExpressType as AllExpressType_ } from "../../Type/index"
import { UpDataType } from '../../Api/Express/interfaceType'
import { Vue,Component, Watch } from "vue-property-decorator"
import ZoomPage from "@/impView/PageSafety";


interface AllExpressType<T=string>{
    loadingShow : boolean;
    userId:T|number;
    token:T;
    SelShowList:boolean[];
    TimeList:Array<GetTimeType>
    TimeIndex:number;
    FromList:GetFromType[];
    FromIndex:number;
    ToList:RoomType[]
    ToIndex:number;
   //  UpList:Array<{ title:T,state:number }>
   //  UpIndex:number;
    UpData:UpDataType<T>;
    StopPullItem:boolean;
    UpDownBool:boolean
    PullDown:boolean;
    StopUpDown:boolean;
    List:Array<AllExpressType_>;

    initData():void;
    handleClickPull(index:number):void;
    handlePullItem(data?:any):void;
    handlePullDown(data?:any):void;
    handleUpDown(data?:any):void;
    handleUpData(bool?:boolean):void;
    handleUpTime():void;
    handleFrom():void;
    handleTo():void;
    handleError(data:string):void;
}

@Component({ name:"AllExpress",components:{ PullItem,List,AllItem,UpDownCom,HeadTop } })
export default class AllExpress extends ZoomPage implements AllExpressType{
   loadingShow = true;
   userId: string|number = ""
   token = ""
   SelShowList = [ false,false,false,false, ]
   TimeList:Array<GetTimeType> = []
   TimeIndex = 0
   FromList:Array<GetFromType> = [];
   FromIndex = 0;
   ToList:Array<RoomType> = []
   ToIndex = 0;
   // UpList = [ { title:"上楼",state:0 },{ title:"不上楼",state:1 } ]
   // UpIndex = 0;
   List:Array<AllExpressType_> = []
   StopPullItem = true; // 是否可以选择 是否上楼
   UpDownBool = true; // 是否上啦加载
   PullDown = false; // 下拉加载
   StopUpDown = false; // 是否上啦加载
   UpData:UpDataType<string> = {
       pageNo:1,
       pageSize:15
   }

   mounted() {
       this.loadingShow = false
       this.userId = Stroage.GetData_ && Stroage.GetData_("userId")
       this.token = Stroage.GetData_ && Stroage.GetData_("token")
       this.initData()
   }

   initData(){
       if ( StoreExpress.GetTimeList.length ) {
           this.TimeList = FilterPullItem(StoreExpress.GetTimeList,"dicCode") as Array<GetTimeType>
       }else{
           this.handleUpTime()
       }
       if ( StoreExpress.GetFromList.length ) {
           this.FromList = FilterPullItem(StoreExpress.GetFromList,"name") as Array<GetFromType>
       }else{
           this.handleFrom()
       }
       if( UserStore.GetRoomList.length ){
           this.ToList = FilterPullItem(UserStore.GetRoomList,"dicCode")
       }else{
           this.handleTo()
       }
       // 筛选条件
       this.TimeIndex = StoreExpress.GetExpressIndex.TimeIndex
       this.FromIndex = StoreExpress.GetExpressIndex.FromIndex
       this.ToIndex = StoreExpress.GetExpressIndex.ToIndex
       // 请求的条件
       this.UpData = StoreExpress.GetExpressUpData
       // 数据的缓存
       if ( StoreExpress.GetExpressList.length ) {
           this.List = StoreExpress.GetExpressList
           this.UpDownBool = false
           this.loadingShow = false
       }else{
           this.handleUpData()
       }
   }

   handleClickPull(idx:number){
       let List:boolean[] = []
       this.SelShowList.forEach((item,index)=>{
           if ( index == 3 && this.StopPullItem) {
               List.push( false )
               return
           }
           if ( idx == index ) {
               List.push( !item )
           }else{
               List.push( false )
           }
       })
       this.SelShowList = List
   }

   handlePullItem(data:any){
       this.UpDownBool = true
       this.UpData.pageNo = 1
       switch( Number( data.state ) ){
           case 0:
               this.UpData = {
                   pageNo:1,
                   pageSize:15
               }
               break;
           case 1:
               this.TimeIndex = data.index
               this.UpData.receiveDate = data.data.title
               break;
           case 2:
               this.FromIndex = data.index
               this.UpData.mailCompany = data.data.title
               break;
           case 3:
               this.ToIndex = data.index
               this.UpData.buildNumberName = data.data.title
               break;
           // case 4:
           //     this.UpIndex = data.index
           //     this.UpData.upDown = data.index
           //     break;
       }
       this.handleUpData(true)
   }

   handlePullDown(data:any){
       this.PullDown = false
       this.StopUpDown = false
       this.UpData.pageNo = 1
       UpAllExpress({
           userId:this.userId,
           token:this.token
       },this.UpData).then( (res:any)=>{
           this.PullDown = true
           console.log( res )
           if ( res.message.code === "200" ) {
               if ( res.data.length < this.UpData.pageSize ) {
                   this.StopUpDown = true
               }else{
                   this.UpData.pageNo += 1
               }
               this.List = res.data
               StoreExpress.SetExpressList( this.List )
           }else{
               this.handleError( res.message.msg )
           }
       } )
   }

   handleUpDown(data:any){
       if ( this.StopUpDown ) return;
       this.UpData.pageNo += 1
       this.UpDownBool = true
       this.handleUpData()
   }

   handleUpData(bool?:boolean){
       UpAllExpress({
           userId:this.userId,
           token:this.token
       },this.UpData).then( (res:any)=>{
           this.loadingShow = false
           this.UpDownBool = false
           if ( res.message.code === "200" ) {
               if ( res.data.length < this.UpData.pageSize ) {
                   this.StopUpDown = true
               }
               if ( bool ) {
                   this.List = res.data
               }else{
                   this.List = this.List.concat( res.data )
               }
               StoreExpress.SetExpressList( this.List )
           }else{
               this.handleError( res.message.msg )
           }
       } )
   }

   handleUpTime(): void {
       this.loadingShow = true
     GetTimes({
         userId:this.userId,
         token:this.token
     },{
         isAdmin:0
     }).then( (res:any)=>{
         this.loadingShow = false
         console.log( res )
         if ( res.message.code === "200" ) {
               this.TimeList = FilterPullItem(res.data.prices,"dicCode") as Array<GetTimeType>
               StoreExpress.SetTimeList( res.data.prices )
         }else{
             this.handleError( res.message.msg )
         }
     } )
   }

   handleFrom(): void {
       this.loadingShow = true
     GetFrom({
         userId:this.userId,
         token:this.token
     }).then((res:any)=>{
         this.loadingShow = false
         console.log( res )
         if ( res.message.code === "200" ) {
               this.FromList = FilterPullItem(res.data,"name") as Array<GetFromType>
               StoreExpress.SetFromList(res.data)
         }else{
             this.handleError( res.message.msg )
         }
     })
   }

   handleTo(): void {
       this.loadingShow = true
       GetSchoolRoom({
           userId:this.userId,
           token:this.token
       }).then( (res:any)=>{
           this.loadingShow = false
           console.log( res )
           if ( res.message.code === "200" ) {
               this.ToList = FilterPullItem(res.data.buildNumbers,"dicCode") as Array<RoomType>
               UserStore.SetRoomList( res.data.buildNumbers )
           }else{
               this.handleError( res.message.msg )
           }
       } )
   }

   handleError( data:string ){
       let str = ""
       if ( data.length ){
           str = data
       }else{
           str = "网络繁忙..."
       }
       Notify({
           message: str,
           color: 'white',
           background: "rgba(0,0,0,.6)",
       });
   }

   @Watch("UpData")
   handleChangeUpData(newVal:UpDataType){
       StoreExpress.SetExpressUpData( newVal )
   }
   @Watch("TimeIndex")
   handleChangeTimeIndex(newVal:any){
       let data = {
           TimeIndex:this.TimeIndex,
           FromIndex:this.FromIndex,
           ToIndex:this.ToIndex,
           // UpIndex:this.UpIndex
       }
       StoreExpress.SetExpressIndex( data )
   }
   @Watch("FromIndex")
   handleChangeFromIndex(newVal:any){
       let data = {
           TimeIndex:this.TimeIndex,
           FromIndex:this.FromIndex,
           ToIndex:this.ToIndex,
           // UpIndex:this.UpIndex
       }
       StoreExpress.SetExpressIndex( data )
   }
   @Watch("ToIndex")
   handleChangeToIndex(newVal:any){
       let data = {
           TimeIndex:this.TimeIndex,
           FromIndex:this.FromIndex,
           ToIndex:this.ToIndex,
           // UpIndex:this.UpIndex
       }
       StoreExpress.SetExpressIndex( data )
   }

   // 是否 可以上楼
   // handleFilterUpDown(){
   //     if ( this.TimeList.length ) {
   //         if ( this.TimeList[ this.TimeIndex ].isAllowUp == 1 ) {
   //             this.StopPullItem = true
   //         }else{
   //             this.StopPullItem = false
   //         }
   //     }
   // }
}
